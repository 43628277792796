"use client";

import React, { useEffect, useState } from "react";
import LinkItem from "./LinkItem";
import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { cn, mdBreakPoint, screenInnerWidth } from "@/lib/utils";
import { useSession } from "next-auth/react";

type Props = {
  logoSrcLinkPath: string;
};

const LeftContainer: React.FC<Props> = ({ logoSrcLinkPath }) => {
  const { renderMiddleContent } = useMainLayout();
  const [logoOnlyInheader, setLogoOnlyInHeader] = useState(false);
  const [collapseMenu, setCollapseMenu] = useState(false);
  const session = useSession();

  const isLessThanMd = screenInnerWidth < mdBreakPoint;

  useEffect(() => {
    setLogoOnlyInHeader?.(
      Boolean(session?.data?.user.id) && collapseMenu && isLessThanMd
    );
  }, [collapseMenu, isLessThanMd, session?.data?.user.id]);

  useEffect(() => {
    if (Boolean(renderMiddleContent)) {
      setCollapseMenu(true);
    } else {
      setCollapseMenu(false);
    }
  }, [renderMiddleContent]);

  return (
    <div className="flex flex-col items-start gap-1 md:flex-row md:items-center md:gap-6">
      <div
        className={cn(
          "flex items-center gap-2",
          !logoOnlyInheader ? "min-w-[240px]" : "w-full"
        )}
      >
        <LinkItem path={logoSrcLinkPath}>
          <img
            src={
              logoOnlyInheader
                ? "/assets/logos/gondola.svg"
                : "/assets/logos/gondola-logo-text.svg"
            }
            alt="gondola-logo"
            className={cn(
              logoOnlyInheader
                ? "h-[40px] min-h-[40px] w-[40px] min-w-[40px]"
                : "h-[33px] w-full min-w-[100px] sm:min-w-[200px] md:h-[40px]"
            )}
          />
        </LinkItem>
      </div>
    </div>
  );
};

export default LeftContainer;
