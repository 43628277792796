"use client";

import { useMainLayout } from "@/app/context/mainLayoutProvider";
import React from "react";
import LinkItem from "./LinkItem";
import { mdBreakPoint, navItems, screenInnerWidth } from "@/lib/utils";
import { useGlobalStore } from "@/app/context/globalStoreProvider";

type Props = {
  isLoggedIn?: boolean;
};

const MiddleComponent: React.FC<Props> = ({ isLoggedIn }) => {
  const { renderMiddleContent } = useMainLayout();
  const { user } = useGlobalStore();

  const isLessThanMd = screenInnerWidth < mdBreakPoint;
  if (renderMiddleContent) return renderMiddleContent;
  return (
    <div className="flex h-full w-full items-center justify-center gap-6">
      {!isLessThanMd && isLoggedIn && user?.status === "EMAIL_SYNCED"
        ? navItems.map((item) => (
            <LinkItem path={item.href} key={item.key}>
              {item.title}
            </LinkItem>
          ))
        : null}
    </div>
  );
};

export default MiddleComponent;
