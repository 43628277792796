"use client";

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

type PageMetadata = {
  title: string;
  description?: string;
  coverLogo?: string;
  pathname?: string;
  originUrl?: string;
};

const defaultMetadata: PageMetadata = {
  title: "Gondola",
  description: "All your travel, one place.",
  coverLogo: "/assets/logos/gondola-cover-logo.png",
  pathname: "/",
  originUrl: typeof window !== "undefined" ? window.location.origin : "",
};

type HeaderContextType = {
  setPageMetadata?: Dispatch<SetStateAction<PageMetadata>>;
};

const HeaderContext = createContext<HeaderContextType>({});

export default function HeaderProvider({ children }: PropsWithChildren) {
  const [pageMetadata, setPageMetadata] =
    useState<PageMetadata>(defaultMetadata);

  const metadataUrl =
    (pageMetadata.originUrl as string) + (pageMetadata.pathname as string);
  const metadataCoverLogo =
    (pageMetadata.originUrl as string) + (pageMetadata.coverLogo as string);

  return (
    <HeaderContext.Provider value={{ setPageMetadata }}>
      <head>
        <title>{pageMetadata.title}</title>
        <meta name="title" content={pageMetadata.title} />
        <meta name="description" content={pageMetadata.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metadataUrl} />
        <meta property="og:title" content={pageMetadata.title} />
        <meta property="og:description" content={pageMetadata.description} />
        <meta property="og:image" content={metadataCoverLogo} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={metadataUrl} />
        <meta property="twitter:title" content={pageMetadata.title} />
        <meta
          property="twitter:description"
          content={pageMetadata.description}
        />
        <meta property="twitter:image" content={metadataCoverLogo} />
      </head>
      {children}
    </HeaderContext.Provider>
  );
}

export const useHeader = () => {
  const { setPageMetadata } = useContext(HeaderContext);

  return { setPageMetadata };
};
